import classNames from 'classnames';
import React, { useRef, useEffect } from 'react';
import styles from './index.module.less';

type GradientButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
};

const ShimmeringButton = ({ children, onClick, className }: GradientButtonProps) => {
  const gradientRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //     const handleAnimationEnd = () => {
  //         if (gradientRef.current) {
  //             gradientRef.current.remove();
  //         }
  //     };

  //     if (gradientRef.current) {
  //         gradientRef.current.addEventListener('animationend', handleAnimationEnd);
  //         return () => {
  //             if (gradientRef.current) {
  //                 gradientRef.current.removeEventListener('animationend', handleAnimationEnd);
  //             }
  //         };
  //     }
  // }, []);

  return (
    <div onClick={onClick} className={classNames(styles.buttonWrapper, className)}>
      <div className={styles.content}>{children}</div>
      <div className={styles.gradientBackground} ref={gradientRef} />
    </div>
  );
};

export default ShimmeringButton;
