import React from 'react';

import styles from './index.module.less';
import { imgUrl } from '@/shared/utils';
import { InsAccountModel } from '@/types/home';
import Image from 'next/image';
import TrackableContent from '@/components/Common/TrackableContent';

const UserDashboard = ({ is_private, profile_pic_url, media_name }: InsAccountModel) => {
  return (
    <div className={styles.itemContent} style={{ background: !is_private ? '#f8f7ff' : '#fef6f5' }}>
      <div className={styles.itemContentLeft}>
        <Image
          src={imgUrl(profile_pic_url, 'mid')}
          width={60}
          height={60}
          alt={'account icon'}
          loading="lazy"
          className={styles.imgContainer}
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.onerror = null;
            // target.src = 'images/layouts/placeholder-icon.webp';
          }}
        />
        <h3>{media_name}</h3>
      </div>
      <TrackableContent is_private={!!is_private} />
    </div>
  );
};

export default UserDashboard;
