
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './index.module.less';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { InsAccountModel, UnAddAccountModel } from '@/types/home';
import { useUserProvider } from '@/context/UserProvider';
import { addInsMonitor, fetchInsMonitorList, fetchInsMonitorSearch } from '@/lib/service';
import { debounce, forEach } from 'lodash-es';
import { AutoComplete, Avatar, Button, Form, message, Modal, Space, Spin } from 'antd';
import { extractInstagramUsername, isInstagramProfileURL } from '@/shared/utils';
import UserDashboard from '@/components/Dashboard/UserDashboard';
import { CloseCircleOutlined } from '@ant-design/icons';
import UnlockModalContent from '@/components/Common/UnlockModalContent';
import CommonSimpleModal from '@/components/Common/CommonSimpleModal';
import { addInsStory } from '@/lib/service/dashboard';
import { LimitAlert } from '../FeeLimitAlert';

const AddAccountContent: React.FC<{
    dataType?: 'default' | 'ins',
    searchValue?: string,
    storySubId?: number,
    open?: boolean,
    /** 是否忽略阶段一，默认不忽略，如果要忽略，请确保打开弹窗时 searchValue 有值 */
    ignoreStageOne?: boolean,
    /** 如果传入 accountData，则不进行阶段一的搜索，直接使用 accountData 进行阶段二 */
    accountData?: InsAccountModel,
    /** 是否忽略阶段一的搜索，而使用外部数据，默认不忽略，如果要忽略，请确保打开弹窗时 accountData 有值 */
    ignoreStageOneSearch?: boolean,
    onClickOpen?: (isOpen?: boolean, isSuccess?: boolean) => void,
    onPlanClick?: (media_name?:string, avatar?: string, media_url?:string) => void
}> = ({
    dataType='default',
    searchValue,
    storySubId,
    open,
    ignoreStageOne,
    accountData,
    ignoreStageOneSearch,
    onClickOpen,
    onPlanClick
}) => {
    const { t } = useTranslation()
    const router = useRouter();

    const [openUnLock, setOpenUnLock] = useState(false);
    // const [open, toggle] = useState(false);
    const [hasAccount, setHasAccount] = useState(false);
    const { onInitAccountList, isLogin, onChangedLoginView, onOpenLoginModal } = useUserProvider();
    const [loading, setLoading] = useState(false);
    const [initLoading, setInitLoading] = useState(true);
    const [accountModel, setAccountModel] = useState<InsAccountModel>();
    const [showPlan, setShowPlan] = useState<string>(undefined);
    const [openPrivateAccount, setOpenPrivateAccount] = useState<any>(null);
    const [currentUnAddAccount, setCurrentUnAddAccount] = useState<UnAddAccountModel>(null);
    const [isBtnAble, setIsBtnAble] = useState(true);
    const [currentUser, setCurrentUser] = useState('');
    const [userData, setUserData] = useState<InsAccountModel[]>([]);
    const [inputOpen, setInputOpen] = useState(false);
    const autoRef = useRef(null);
    const [showLimit, setShowLimit] = useState(false);

    useEffect(() => {
        if (open) {
          if (accountData && ignoreStageOneSearch) {
            // 使用外部数据，跳过阶段一的搜索，直接进行阶段二
            setHasAccount(true);
            setAccountModel(accountData);
          } else if (searchValue && ignoreStageOne) {
            setHasAccount(false)
            // 根据外部传入的值，请求数据，实现阶段一跳过
            onFinish({
                accountName: searchValue
            });
          }
        } else {
            setHasAccount(false)
        }
    }, [open])

    const tooltip = () => {
        return (
            <div
                style={{
                    overflow: 'hidden',
                    border: '1px solid var(--app-text-30-color)',
                    borderRadius: '10px',
                }}
            >
                <Image
                    src={'/images/report/example-icon.webp'}
                    width={572}
                    height={212}
                    alt={'icon'}
                    style={{ width: '100%', objectFit: 'contain' }}
                />
            </div>
        );
    };

    const debounceFetcher = (value: string) => {
        setLoading(true);
        setIsBtnAble(false);
        fetchInsMonitorSearch(value, router.locale)
            .then((res) => {
                setLoading(false);
                setIsBtnAble(true);
                if (res.code == 0 && res.data?.length > 0) {
                    setInputOpen(true);
                    autoRef.current?.focus();

                    let tempData: InsAccountModel;
                    forEach(res.data, (item) => {
                        if (item.media_name === value) {
                            tempData = item;
                        }
                    });
                    if (tempData !== undefined) {
                        setUserData([]);
                        if (tempData?.is_private === 2) {
                            showInvalidNameAlert();
                            return;
                        } else {
                            setHasAccount(true);
                            setAccountModel(tempData);
                        }
                    } else {
                        if (ignoreStageOne) {
                          // 跳过阶段一，暂时忽略多个用户的情况，未精确匹配到用户时，直接提示无效
                          setUserData([]);
                          showInvalidNameAlert();
                          return;
                        } else {
                          setUserData(res.data);
                        }
                    }
                } else if (res?.code === 10001) {
                    showInvalidNameAlert();
                    return;
                } else {
                    message.error(res.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                message.error(error.message);
                setIsBtnAble(true);
            });
    };

    const fetchList = async () => {
        setInitLoading(true);
        const monitorList = await fetchInsMonitorList();
        if (monitorList.code === 0) {
            onInitAccountList(monitorList.data);
        } else if (monitorList.code === 40001) {
            router.replace('/');
        } else {
            message.error(monitorList.message);
        }
        setInitLoading(false);
    };

    // 搜索
    const onSearch = useCallback(
        debounce((value: string) => {
            if (!value || isInstagramProfileURL(value)) {
                setUserData([]);
                return;
            }
            autoRef.current?.blur();
            setUserData([]);
            debounceFetcher(value);
        }, 500),
        [],
    );

    // 清除内容
    const onClear = () => {
        setCurrentUser('');
        setAccountModel(undefined);
        setUserData([]);
    };

    // 选择user
    const onSelect = (val: string) => {
        setInputOpen(false);
        setHasAccount(true);
        if (userData.length === 1) {
            if (userData[0]?.is_private === 2) {
                setHasAccount(false);
                showInvalidNameAlert();
                return;
            }
            setCurrentUser(val);
            onFinish({ accountName: val });
            return;
        }
        setCurrentUser(val);
        setUserData(userData.filter((user) => user.media_name === val));

        const tempData = userData.find((user) => user.media_name === val);
        if (tempData?.is_private === 2) {
            setHasAccount(false);
            showInvalidNameAlert();
            return;
        } else {
            setAccountModel(tempData);
        }
    };

    const showInvalidNameAlert = () => {
        setOpenPrivateAccount({
            title: t('Invalid Instagram Username'),
            desc: t('The username you entered is not valid. Please check the username and try again.'),
            btnTitle: t('TRYAGAIN'),
        });
        onClickOpen && onClickOpen(false);
    };

    const accountNameSearchRequest = (accountName?: string) => {
        setLoading(true);
        setIsBtnAble(false);
        fetchInsMonitorSearch(accountName, router.locale)
            .then((res) => {
                setLoading(false);

                setTimeout(() => {
                    setIsBtnAble(true);
                }, 1500);

                if (res.code == 0 && res.data?.length > 0) {
                    const data = res.data.filter((item) => item.media_name === accountName);
                    if (!data.length || res.data[0]?.is_private === 2) {
                        showInvalidNameAlert();
                        return;
                    }

                    setAccountModel(res.data[0]);
                    setHasAccount(true);
                    onClickOpen && onClickOpen(true);
                } else if (res?.code === 20001) {
                    setOpenPrivateAccount({
                        title: t('OopsPrivateProfileDetected'),
                        desc: (
                            <>
                                <div style={{ marginBottom: '24px' }}>
                                    {t('ThisInstagramAccountIsPrivate_Desc')}
                                </div>
                                <UserDashboard {...accountModel} />
                            </>
                        ),
                        btnTitle: t('TRYAGAIN'),
                    });
                    onClickOpen && onClickOpen(false);
                    return;
                } else if (res?.code === 20002) {
                    showInvalidNameAlert();
                    return;
                } else {
                    message.error(res.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                message.error(error.message);
                setIsBtnAble(true);
            });
    };


    // 提交搜索
    const onFinish = ({ accountName }: { accountName: string }) => {
        // 阶段一还未获取账户信息
        if (!hasAccount) {
            // 处理输入的账户名，去除前后空格，并转换为小写
            accountName = (accountName || '').trim().toLowerCase();
            if (isInstagramProfileURL(accountName)) {
                // ga_logEvent(EventName.dashboard_Check);
                const username = extractInstagramUsername(accountName);
                accountNameSearchRequest(username);
            } else {
                onSearch(accountName);
            }
            return;
        }

        // 阶段二，如果账户是私密的
        if (accountModel?.is_private !== 0) {
            setAccountModel(null);
            setHasAccount(false);
            // 阶段一跳过，关闭弹窗
            if (ignoreStageOne || ignoreStageOneSearch) {
              onClickOpen && onClickOpen(false);
            }
            return;
        }

        // 正常账户
        if (hasAccount) {
            if (isLogin) {

                if (dataType === 'ins') {
                    setLoading(true);

                    addInsStory(accountModel?.media_name, storySubId).then((res)=>{
                        setLoading(false);
                        if (res.code === 0) {
                            onClickOpen && onClickOpen(false, true);
                            setCurrentUnAddAccount(null);
                        } else if (res?.code === 22007) {
                            setShowLimit(true);
                            return;
                        }

                        if (res.code !== 0) {
                            message.error(res.message);
                        }
                    }).catch((error) => {
                        setLoading(false);
                        message.error(error.message);
                    });
                } else {
                    if (currentUnAddAccount === null) {
                        setOpenUnLock(true)
                        onClickOpen && onClickOpen(false)
                        return;
                    }
                    setLoading(true);

                    addInsMonitor(
                        accountModel.media_name,
                        accountModel.profile_pic_url,
                        accountModel.media_url,
                        currentUnAddAccount?.subs_id,
                        router.locale,
                    ).then((res) => {
                            setLoading(false);
                            if (res.code === 0) {
                                fetchList();
                                onClickOpen && onClickOpen(false, true);
                                setCurrentUnAddAccount(null);

                            } else if (res?.code === 20000) {
                                setShowPlan(res?.message);
                                onClickOpen && onClickOpen(false);
                                return;
                            } else {
                                message.error(res.message);
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            message.error(error.message);
                        });
                }
            } else {
                onChangedLoginView(false);
                onOpenLoginModal(true);
            }
        }
    };

    const modalContent = () => {
        return (
            <Modal
                className={styles.modalContainer}
                open={open}
                onCancel={() => {
                    onClickOpen && onClickOpen(false);
                    onClear();
                }}
                destroyOnClose={true}
            >
                <Form
                    name="normal_edit_form"
                    layout="vertical"
                    className={styles.formContent}
                    initialValues={{ accountName: searchValue }}
                    onFinish={onFinish}
                    style={{ padding: '20px 24px' }}
                    validateTrigger="onSubmit"
                >
                    <div className={styles.titleContainer}>
                        {accountModel?.is_private ? t('OopsPrivateProfileDetected') : t('ADD new account')}
                    </div>
                    {/* 阶段一跳过 */}
                    <Form.Item hidden={ignoreStageOne}>
                        <div className={styles.desc}>
                            {hasAccount
                                ? accountModel?.is_private
                                    ? t('ThisInstagramAccountIsPrivate_Desc')
                                    : (
                                        dataType === 'ins' ? t("Add to your tracking list to continuously monitor their posts, stories, and downloadable content, even after stories disappear.") : t('AddToYourTrackingListToSeeTheirLikes_Desc')
                                    )
                                : <div>
                                    <p style={{ color: '#333', marginBottom: '8px' }}>{t("No one will ever know. Get the answers you need:")}</p>
                                    {
                                        dataType === 'ins' ? <>
                                            <p>{t("👁 Enjoy Unlimited Viewing & Downloads")}</p>
                                            <p>{t("✨ Save Time with One-Click Bulk Downloads")}</p>
                                            <p>{t("🔗 Discover Hidden Mentions & Links")}</p>
                                        </> : <>
                                            <p>{t("🔗 See their closest connections")}</p>
                                            <p>{t("👁 Track new follows and unfollows")}</p>
                                            <p>{t("🔍 Access in-depth activity reports")}</p>
                                        </>
                                    }
                                </div>}
                        </div>
                    </Form.Item>
                    {/* 阶段一跳过 */}
                    {!hasAccount && <Form.Item hidden={ignoreStageOne}>{tooltip()}</Form.Item>}
                    {hasAccount ? (
                        <Form.Item className="no-asterisk">
                            <UserDashboard {...accountModel} />
                        </Form.Item>
                    ) : (
                        // 阶段一跳过
                        <Form.Item
                            name="accountName"
                            rules={[{ required: true, message: t('PleaseInputAnAccount') }]}
                            className="no-asterisk"
                            hidden={ignoreStageOne}
                        >
                            <AutoComplete
                                ref={autoRef}
                                open={inputOpen}
                                options={(userData || []).map((d) => ({
                                    value: d.media_name,
                                    label: (
                                        <Space>
                                            <Avatar src={d.profile_pic_url} /> {d.media_name}
                                        </Space>
                                    ),
                                }))}
                                style={{ pointerEvents: loading ? 'none' : 'auto' }}
                                onClear={onClear}
                                onSelect={onSelect}
                                onBlur={() => setInputOpen(false)}
                                onClick={() => {
                                    if (!inputOpen) setInputOpen(true);
                                }}
                                className={styles.inputItem}
                                placeholder={t('EnterTheAccountUsernameAsInTheExampleAbove')}
                                allowClear={{ clearIcon: <CloseCircleOutlined /> }}
                                notFoundContent={loading ? <Spin size="small" /> : null}
                            />
                        </Form.Item>
                    )}

                    {/* 阶段一跳过，直接尝试加载数据 */}
                    {ignoreStageOne && !hasAccount && (
                        <div className='h-[180px] flex justify-center items-center'>
                            <Spin size="large" />
                        </div>
                    )}

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={styles.bottomButton}
                            style={{ background: isBtnAble ? '' : '#999' }}
                            loading={loading}
                            // 阶段一跳过
                            hidden={ignoreStageOne && !hasAccount}
                        >
                            {hasAccount
                                ? accountModel?.is_private
                                    ? t('TRYAGAIN')
                                    : t('ADD ACCOUNT')
                                : t('CHECK')}
                        </Button>
                        <p style={{ color: "var(--app-text-30-color)", marginTop: '10px' }}>{t("Note: Only public accounts can be tracked.")}</p>
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const planModalContent = () => {
        return (
            <Modal
                className={styles.plamModalContainer}
                styles={{ body: { padding: '0px' } }}
                open={showPlan != undefined}
                onCancel={() => {
                    setShowPlan(undefined);
                }}
                destroyOnClose={true}
                footer={null}
            >
                <div
                    // name="normal_edit_form"
                    // layout="vertical"
                    className={styles.formContent}
                    style={{
                        padding: '20px 24px 70px 20px',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: '100% 100%',
                        // backgroundImage: `url('/images/plan/plan_alert_bg.webp')`,
                        backgroundImage: `url('https://res-front.pumpsoul.com/ins-prod/image/plan_alert_bg.webp')`,
                        borderRadius: '10px',
                    }}
                >
                    <div className={styles.titleContainer}>{t('One Step Away from Full Access')}</div>
                    <div className={styles.desc} style={{ marginBottom: '60px' }}>
                        {showPlan}
                    </div>

                    <Button
                        className={styles.bottomButton}
                        style={{
                            color: `#FFF`,
                            background: `linear-gradient( 180deg, #725CFF 0%, rgba(141,131,255,0.8) 100%)`,
                        }}
                        onClick={() => {
                            if (dataType === 'ins') {
                                onPlanClick && onPlanClick(accountModel.media_name, accountModel.profile_pic_url, accountModel.media_url)
                            } else {
                                router.push(
                                    `/plan?media_name=${accountModel.media_name}&avatar=${accountModel.profile_pic_url}&media_url=${accountModel.media_url}`,
                                );
                            }
                            setShowPlan(undefined);
                        }}
                    >
                        {t('Unlock Now')}
                    </Button>
                </div>
            </Modal>
        );
    };



    return (<>
        {open && modalContent()}
        {planModalContent()}

        {openUnLock && <UnlockModalContent
            logo={accountModel?.profile_pic_url}
            name={accountModel?.media_name}
            open={openUnLock}
            onClose={(isOk?: boolean) => {
                if (isOk) {
                    if (dataType === 'ins') {
                        onPlanClick && onPlanClick(accountModel.media_name, accountModel.profile_pic_url, accountModel.media_url)
                    } else {
                        router.push(
                            `${process.env.NEXT_APP_WEB_HOST}/plan?media_name=${accountModel.media_name}&avatar=${accountModel.profile_pic_url}&media_url=${accountModel.media_url}`,
                        );
                    }
                }
                setOpenUnLock(false)
            }} />}

        <CommonSimpleModal
            open={openPrivateAccount !== null}
            title={openPrivateAccount?.title}
            desc={openPrivateAccount?.desc}
            btnTitle={openPrivateAccount?.btnTitle}
            closeAction={(isOk: boolean) => {
                setOpenPrivateAccount(null);
                if (isOk) {
                    setHasAccount(false);
                    // 阶段一如果跳过，不再打开弹窗
                    if (!ignoreStageOne) {
                      onClickOpen && onClickOpen(true);
                    }
                }
            }}
        />

        <LimitAlert
            open={showLimit}
            closeAction={(isOk) => {
                setShowLimit(false)
                if (isOk) {
                    onPlanClick && onPlanClick(accountModel.media_name, accountModel.profile_pic_url, accountModel.media_url)
                }
            }}
        />
    </>);
};

export default AddAccountContent;
