import React from 'react';
import { Modal } from 'antd';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import ShimmeringButton from '../ShimmeringButton';
import { imgUrl } from '@/shared/utils';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';

const UnlockModalContent = ({ open, logo, name, onClose }: { open: boolean, logo?: string, name?: string, onClose?: (isOk?: boolean) => void }) => {
  const { t } = useTranslation()

  return (
    <Modal
      className={styles.modalContainer}
      styles={{ body: { padding: '0px' } }}
      open={open}
      onCancel={() => {
        onClose && onClose()
      }}
      destroyOnClose={true}
      footer={null}
    >
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.profileSection}>
            <Image
              src={imgUrl(logo ?? '')}
              alt="Profile"
              width={66}
              height={66}
              style={{ borderRadius: '50%', border: `2px solid white` }}
            />

          </div>

        </div>

        <div className={styles.title1}>
          {t("Caught yourself checking their Instagram again?")}
        </div>

        <div className={styles.title2}>
          <span>{t("Stop the guessing games—")}</span>
          <span>{t("track their Insta activity with Dolphin Radar.")}</span>
        </div>

        <div className={styles.title3}>
          {t("Know more, stress less.")}
        </div>

        <ShimmeringButton className={styles.actionButton} onClick={() => {
          onClose && onClose(true)
          ga_logEvent(EventName.Unlock_Bottom_StartTrackingNow_Btn)
        }}>
          {/* 🚀 {t("Unlock it now")} */}
          👉 {t("Start Tracking Now!")}
        </ShimmeringButton>

      </div>
    </Modal >
  );
};

export default UnlockModalContent;